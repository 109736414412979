import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia'
import './registerServiceWorker';
import 'lib-flexible/flexible.js';
// 确保路径正确

// tyz
import 'element-plus/dist/index.css'

// 创建 Vue 应用
const app = createApp(App);

// 遍历图标组件库，并全局注册每个组件
const ElementPlusIconsVue = require('@element-plus/icons-vue');
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

const pinia = createPinia()

app.use(router).use(pinia).mount('#app');

const debounce = (fn, delay) => {
    let timer
     return (...args) => {
       if (timer) {
         clearTimeout(timer)
       }
       timer = setTimeout(() => {
         fn(...args)
       }, delay)
     }
  }
    
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
     constructor(callback) {
       callback = debounce(callback, 200);
       super(callback);
     }
  }