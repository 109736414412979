import {createRouter, createWebHashHistory} from "vue-router";

const routes = [
  {
    path: '/page',
    name: 'page',
    component: () => import("../views/homePage.vue")
  },
  {
    path: '/',
    redirect: '/page'
  }

]

let baseWebHistory = process.env.NODE_ENV === "production" ? "/web/" : "/"

const router = createRouter({
  history: createWebHashHistory(baseWebHistory),
  routes
})

export default router
